// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-archief-jsx": () => import("./../../../src/pages/archief.jsx" /* webpackChunkName: "component---src-pages-archief-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-over-skpd-jsx": () => import("./../../../src/pages/over-skpd.jsx" /* webpackChunkName: "component---src-pages-over-skpd-jsx" */),
  "component---src-templates-event-type-jsx": () => import("./../../../src/templates/event-type.jsx" /* webpackChunkName: "component---src-templates-event-type-jsx" */)
}

